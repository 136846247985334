<template>
  <page-header title="История просмотров" />

  <template v-if="views !== null">
    <template v-if="views.length > 0">
      <q-card>
        <div v-for="view in views" :key="view.id" class="view">
          <div class="view--content">
            <div class="view--name">
              <router-link :to="{ name:'terms_view', params: { id: view.term.id }}">{{ view.term.name }}</router-link>
            </div>
            <div class="view--teaser">
              {{ view.term.teaser }}
            </div>
          </div>
          <div class="view--viewed-at">
            <div v-html="formatDate(view.viewedAt)" />
            <div v-if="showUsers"><router-link :to="{name: 'users_view', params: {id: view.user.id}}">{{ view.user.name }}</router-link></div>
          </div>
        </div>
      </q-card>

      <pagination :page="filter.page" :pages="pages" />
    </template>
    <div v-else class="no-results-wrapper">
      <q-card class="no-results">
        <div>
          <img src="../../assets/icons/no-results.svg" class="no-results__icon" alt="">
          <div class="no-results__title">У вас пока нет просмотров</div>
          <div class="no-results__content">
            Здесь будут отображаться слова, которые
            вы просматривали.
          </div>
        </div>
      </q-card>
    </div>
  </template>
</template>

<script>
import loading from '../../loading.js'
import { api as viewsApi } from '../../api/views.js'
import { api as authApi } from '../../api/auth.js'
import PageHeader from '../../blocks/PageHeader.vue'
import Pagination from '../../blocks/Pagination.vue'

const createFilter = function(query) {
  const filter = {
    page: 1,
  }

  if (query['page']) {
    filter.page = parseInt(query['page'])
  }

  return filter
}

async function preparePage(callback, to) {
  loading.start()
  const filter = createFilter(to.query)
  const views = await viewsApi.findBy(filter)
  const setInitData = vm => {
    vm.views = views
    vm.pages = views.meta.pages
    vm.setFilter(filter)
  }
  callback(setInitData)
  loading.stop()
  loading.updateTitle(to.meta.title || '')
}

export default {
  components: {
    PageHeader,
    Pagination,
  },
  setup() {
    return {
      showUsers: authApi.getCurrentUser().role.id === 'administrator'
    }
  },
  async beforeRouteEnter(to, from, next) {
    await preparePage(setInitData => {
      next(setInitData)
    }, to)
  },
  async beforeRouteUpdate(to, from, next) {
    await preparePage(setInitData => {
      setInitData(this)
      next()
    }, to)
  },
  data() {
    return {
      views: null,
      filter: { page: 1 },
      pages: 1,
    }
  },
  methods: {
    setFilter(filter) {
      this.filter = filter
    },
    formatDate(date) {
      const format = value => value < 10 ? `0${value}` : value
      let day = format(date.getDate())
      let month = format(date.getMonth() + 1)
      let year = date.getFullYear()
      let hours = format(date.getHours())
      let minutes = format(date.getMinutes())
      return `${hours}:${minutes}&nbsp;&nbsp;${day}.${month}.${year}`
    },
  }
}
</script>

<style lang="scss">
@import "~@/spa/styles/quasar.variables.scss";

.page-views-list {
  display: flex;
  flex-direction: column;
}

.view {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  border-top: 2px solid #F5F6FA;

  &--content {
    flex: 1;
  }

  &--teaser {
    margin-top: 6px;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
  }

  &--viewed-at {
    font-size: 12px;
    line-height: 16px;
    text-align: right;

    @media (max-width: $breakpoint-xs-max) {
      & {
        flex: 1 1 100%;
      }
    }
  }
}

.no-results-wrapper {
  display: flex;
  flex: 1;
}

.no-results {
  position: relative;
  width: 100%;
  max-width: 550px;
  margin: auto;
  padding: 60px 60px 60px 122px;

  &__icon {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 73px;
  }

  &__title {
    font-family: Raleway, sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }

  &__content {
    margin-top: 27px;
    color: rgba(0, 0, 0, 0.53);
  }
}
</style>

import { http, newResults, defined } from './request.js'

class View {
  constructor() {
    this.id = null
    this.term = null
    this.date = null
    this.user = null
  }
}

export const api = {
  /**
   * Создает пустой объект просмотра.
   * @returns {View}
   */
  create: function() {
    return new View()
  },

  parse: function(data) {
    const view = this.create()

    if (defined(data.uuid)) {
      view.id = data.uuid
    }
    if (defined(data.term_uuid)) {
      view.term = {
        id: data.term_uuid,
        name: data.term_title,
        teaser: data.term_subtitle,
      }
    }
    if (defined(data.viewed_at)) {
      view.viewedAt = new Date(data.viewed_at)
    }
    if (defined(data.user_uuid)) {
      view.user = {
        id: data.user_uuid,
        name: data.user_title,
      }
    }

    return view
  },

  findBy: async function(filter = {}) {
    const results = newResults()

    const params = {}
    if (filter.page) {
      params['page'] = filter.page - 1
    }
    if (filter.limit) {
      params['items_per_page'] = filter.limit
    }
    if (filter.user) {
      params['user'] = filter.user.id
    }
    params['sort_by'] = 'viewed_at'
    params['sort_desc'] = true

    const json = await http.get('/term-views', params)

    if (json.terms_views) {
      json.terms_views.forEach(viewData => {
        const view = this.parse(viewData)
        results.push(view)
      })

      results.meta.pages = parseInt(json?.filter?.pager?.total_pages)
      results.meta.total = parseInt(json?.filter?.pager?.total_items)
    }

    return results
  },
}
